import {
  TStoreUi,
  TAction,
  TLoading
} from 'store/ui/d';
import {
  UI_SET_LOADING,
  UI_RESET_LOADING,
  UI_SET_MODAL
} from 'store/ui/types';

const initial = {

} as TStoreUi;

const userState = (state = initial, action: TAction): TStoreUi => {

  switch (action.type) {

    case UI_SET_LOADING: {
      const { timer, isSkeleton, text, isMini } = action.payload as TLoading || {};
      if(typeof isMini !== 'undefined') return {
        ...state,
        loading: {
          isLoading: false,
          isMini,
        }
      };
      if(typeof isSkeleton !== 'undefined') {
        return {
          ...state,
          loading: {
            isLoading: false,
            isSkeleton,
          }
        };
      }
      return {
        ...state,
        loading: {
          isLoading: true,
          timer: timer ? Number(timer) : void(0),
          isSkeleton: void(0),
          isMini: void(0),
          text
        }
      };
    }


    case UI_RESET_LOADING : {
      if(!state?.loading?.isLoading && !state?.loading?.timer && !state?.loading?.isSkeleton && !state.loading?.isMini) return state;
      return {
        ...state,
        loading: {
          isLoading: false,
          timer: void(0),
          isSkeleton: false,
          isMini: false,
          text: void(0)
        }
      };
    }


    case UI_SET_MODAL:
      return { ...state, modal: action?.payload as any };

    default:
      return state;
  }

};

export default userState;
