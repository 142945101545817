import React from 'react';
import {AppContainer} from "./style";
import './index.css';
import {Provider} from "react-redux";
import store from "store";
import {HashRouter} from "react-router-dom";
import AppRouter from "../src/routing/AppRouter";
import SpinnerCenter from "./components/Spinner";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
function App() {
  return (
      <Provider store={store}>
        <HashRouter  basename={''}>
          <AppContainer>
            <AppRouter />
            <SpinnerCenter />
              <ToastContainer
                  position='top-center'
                  autoClose={3000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={false}
                  pauseOnHover
              />
          </AppContainer>
        </HashRouter>
      </Provider>
  );
}

export default App;
