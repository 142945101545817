import styled from "styled-components";
import {Box} from "@mui/material";
import {CustomColors} from "./constants/Colors";

export const AppContainer = styled(Box)`
  &&& {
    background-color: white;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  
  p {
    margin: 0;
  }
`
export const Flex=  styled.div`
  display: flex;
`
