import { TUser }         from 'store/auth/d';
import {
    AUTH_SET_USER,
    AUTH_CLEAR_USER, AUTH_SET_FILE_ID, AUTH_CLEAR_FILE_ID
} from 'store/auth/types';

export const _actionAuthSetUser = (payload: TUser) => ({
    type: AUTH_SET_USER,
    payload
});

export const _actionAuthClearUser = () => ({
    type: AUTH_CLEAR_USER
});

export const _actionSetFileId = (id: number) => ({
    type: AUTH_SET_FILE_ID,
    payload: id
})

export const _actionClearFileId = ()=> ({
    type: AUTH_CLEAR_FILE_ID
})
