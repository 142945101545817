import React from "react";
import {Route, Routes} from 'react-router-dom';
import {ComponentLazy} from '../components/ComponentLazy';
import {PathConstants} from "./pathConstants";
import {Navigate} from "react-router";

export type TRoute = {
    guid: string;
    title?: string;
    path?: string;
    children?: TRoute[];
    component?: React.FC;
    noMenu?: boolean;
    noRoute?: boolean;
};

const Home = React.lazy(() => import('../views/HomeView/HomeView'));
const Login = React.lazy(() => import('../views/LoginView/LoginView'));
const AuthLayout = React.lazy(() => import('layout/auth'));
const MainLayout = React.lazy(() => import('layout'));

const AppRouter = () => {

    return (
        <Routes>
            <Route element={<ComponentLazy component={MainLayout}/>}>
                <Route path={PathConstants.HOME} element={<ComponentLazy component={Home}/>}/>
                <Route path="*" element={<Navigate to={PathConstants.HOME}/>}/>
            </Route>
            <Route element={<ComponentLazy component={AuthLayout}/>}>
                <Route path={PathConstants.LOGIN} element={<ComponentLazy component={Login}/>}/>
                <Route path="*" element={<Navigate to={PathConstants.LOGIN}/>}/>
            </Route>
        </Routes>
    );
};

export default AppRouter;
