import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Dispatch } from 'react';
import ui from 'store/ui';
import auth from 'store/auth';
import { TStoreUi } from 'store/ui/d';
import { LocalStorage } from 'utils/localService/localStorage';
import { TStoreAuth } from 'store/auth/d';
import { getUser } from 'api/user';
import { merge, pick } from 'lodash';
import { _actionAuthSetUser } from 'store/auth/actions';


export type TReduxStore = {
    auth: TStoreAuth,
    ui: TStoreUi
}

const rootReducer = combineReducers({
    ui,
    auth
});

const middleware = thunk as ThunkMiddleware;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)));

const currentState = {
    isInit: false
};


const reduxGetUser = async (dispatch: Dispatch<any>)=> {
    const uiData = getUiData() as any;
    if (currentState.isInit || !uiData) return;
    try {
        const { result } = await getUser(uiData?.id as number) as any;
        if (!result) return;
        const user = pick(result, ['id', 'username', 'email']) as any;
        dispatch(_actionAuthSetUser(user));
    }catch (e) {
        console.log(e);
    }
};

const getUiData = () => {
    const { getUser } = LocalStorage;
    const uiData = getUser() as any;
    if (!uiData) return;
    return uiData;
};


export const reduxInitAwait = async (dispatch: Dispatch<any>) => {
    const uiData = getUiData() as any;
    if (!uiData) return;
    dispatch(_actionAuthSetUser(merge({},
        uiData?.id ? {
            id: uiData.id,
            username: uiData.username,
            email: uiData.email
           }: uiData?.id ? uiData : {})));
    reduxGetUser(dispatch).then();
    currentState.isInit = true;
};


export const reduxInit = (dispatch: Dispatch<any>) => {
    reduxInitAwait(dispatch).then();
};

store.subscribe(() => {
    if (!currentState.isInit) {
        return;
    }
});

export default store;
