import {
    TStoreAuth,
    TUser,
    TAction
} from 'store/auth/d';
import {
    AUTH_SET_USER,
    AUTH_CLEAR_USER, AUTH_SET_FILE_ID, AUTH_CLEAR_FILE_ID
} from 'store/auth/types';

export const initState: TStoreAuth = {
    user: {} as TUser,
    fileId: 0
};

const authState =  (state = initState, action: TAction) => {
    switch(action.type) {
        default:
            return state;
        case AUTH_SET_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload as TUser
                }
            };
        }
        case AUTH_CLEAR_USER: {
            return {
                ...state,
                user: {}
            };
        }

        case AUTH_SET_FILE_ID: {
            return {
                ...state,
                fileId: action.payload
            }
        }
        case AUTH_CLEAR_FILE_ID: {
            return {
                ...state,
                fileId: 0
            };
        }
    }
};

export default authState;
