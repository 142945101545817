import React from "react";
import {guid} from "../utils";

export class PathConstants {
    static HOME = "/"
    static LOGIN = "/auth"
}

const HOME = React.lazy(() => import('../views/HomeView/HomeView'));
const LOGIN = React.lazy(() => import('../views/LoginView/LoginView'));

export const routerList = [
    {
        guid: guid(),
        title: 'Konvertor',
        path: PathConstants.HOME,
        component: HOME
    },
    {
        guid: guid(),
        title: 'Konvertor',
        path: PathConstants.LOGIN,
        component: LOGIN
    },
]
