import {LocalStorage} from 'utils/localService/localStorage';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {TLogin} from 'api/d';
import store from 'store';
import {_actionAuthClearUser} from "store/auth/actions";

export const dataToken = {} as any;


const logout = ()=> {
    dataToken.token = undefined;
    LocalStorage.clearRefreshToken();
    store.dispatch(_actionAuthClearUser());
    LocalStorage.clearUser();
    window.location.href = '/#/auth';
};


export const baseUrl = (()=> {
    const url = window.location.href;
    return (url.includes('localhost') || url.includes('127.0.0.1')) ? 'http://localhost:5000' : 'http://37.27.202.14:5000';
})();
const getNewToken = async () => {
    const refreshToken = LocalStorage.getRefreshToken();
    try {
        const response = await request({
            url: '/auth/refresh-token',
            data: {
                token: refreshToken
            }
        }) as any;
        if (!response.data?.ok) throw Error('Invalid Token');
        dataToken.token = response.data.token || '';
    } catch (e) {
        /** logout */
        logout();
        return;
    }
};



const request = async ({
                           headers,
                           baseURL = baseUrl,
                           url,
                           method = 'POST',
                           data = {},
                           params = {},
                           timeout = 30000
                       }: AxiosRequestConfig): Promise<AxiosResponse> => {
    const _url = url as string;
    const isNeedToken = (() => {
        if (_url.startsWith('/auth/login') || _url.startsWith('/auth/refresh-token')) return false;
        return true;
    })();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
        Authorization: isNeedToken && `Bearer ${dataToken?.token}`,
        'Access-Control-Expose-Headers': 'fronted-permission-sha'
    };

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        axios({ withCredentials: true, headers: header, baseURL, url, method, data, timeout, params })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

const axiosRequest = async (requestData: any) => {
    try {
        const data = await request(requestData);
        return data;
    } catch (e: any) {
        if (e?.response?.status === 401) {
            await getNewToken();
            try {
                return request(requestData);
            } catch (e) {
                /** show notification */
                //console.log(e)
                return;
            }
        }
        if(e?.code === 'ERR_NETWORK') {
            logout();
            return;
        }
        throw e;
    }
};

export const API = {
    post: (url: string, data: any, options = {} as any) => axiosRequest({
        url,
        data,
        ...options
    }),
    get: (url: string, params?: any, baseURL?: string, options = {} as any) => axiosRequest({
        url,
        method: 'GET',
        params,
        baseURL,
        ...options
    })
};

export const login = async  (data: TLogin) =>  {
    const response = await API.post('/auth/login', data) as any;
    return response?.data || {};
};


export const uploadFiles = async (files: any) => {
    return (await API.post('/main/uploadFiles', files, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })) as any || {} as any
}


export const exportFile = async () => {
    const response = await  fetch(`${baseUrl}/main/exportFile`, {
        headers: {
          Authorization: `Bearer ${dataToken.token}`
        },
        credentials: 'include',
        method: 'GET'
    }) as any
    // Extract filename from header
    const filename = response.headers.get('content-disposition')
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim()
    ;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    link.parentNode.removeChild(link);
    // Download the file
    //saveAs(blob, filename);
}
