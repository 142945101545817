import React, { useEffect } from 'react';
import {
  SpinnerContainer,
  SpinnerText,
  SpinnerProgress
} from './style';
import { useLoading } from '../../hooks/useLoading';

export const Spinner = ({ text }: { text?: string }) => {

  return (
    <SpinnerContainer>
      <SpinnerProgress size={'4rem'} />
      <SpinnerText>{text ? text : 'Please wait...'}</SpinnerText>
    </SpinnerContainer>
  );
};



const SpinnerCenter = ({ text }: { text?: string }) => {

  const {  isLoading, timer, text:_text, resetLoading } = useLoading();

  useEffect(() => {
    let th = 0;
    if (timer) {
      th = setTimeout(() => resetLoading(), timer) as any;
    }
    return () => {
      clearTimeout(th);
    };
  }, [timer, resetLoading]);

  return isLoading ? <Spinner text={text || _text} /> : <></>;

};

export default SpinnerCenter;
